.card-items {
    list-style: none;
    margin: 0;
    padding: 0;
    max-height: 20rem;
    overflow: auto;
}

.total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    font-size: 1.5rem;
    margin: 1rem 0;
}

.actions {
    text-align: right;
}

.actions button {
    font: inherit;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid #6b068a;
    padding: 0.5rem 2rem;
    border-radius: 25px;
    margin-left: 1rem;
}

.actions button:hover,
.actions button:active {
    background-color: #47035d;
    border-color: #47035d;
    color: white;
}

.actions .button--alt {
    color: #6b068a;
}

.actions .button {
    background-color: #6b068a;
    color: white;
}

.card {
    padding: 1.5rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    background-color: white;
}