.meal {
    display: flex;
    justify-content: space-between;
    margin: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #ccc;
}

.meal h3 {
    margin: 0 0 0.25rem 0;
}

.description {
    font-style: italic;
}

.price {
    margin-top: 0.25rem;
    font-weight: bold;
    color: #6b068a;
    font-size: 1.3rem;
}