.promo-text {
    text-align: center;
    max-width: 45rem;
    width: 90%;
    margin: auto;
    margin-top: -10rem;
    position: relative;
    background-color: #6b068ae8;
    color: white;
    border-radius: 15px;
    padding: 1rem;
    box-shadow: 0 1px 20px 10px rgba(0, 0, 0, 0.25);
}

.promo-text h2 {
    font-size: 2rem;
    margin-top: 0;
}